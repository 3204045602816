


































import { Component, Vue } from 'vue-property-decorator';

import ExLoading from '@/globalComponents/ExLoading/index.vue';
import ContentLoader from '@/components/ContentLoader/index.vue';
import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';

@Component({
  components: {
    ExLoading,
    ContentLoader,
    BoxContainer
  }
})
export default class ExamsLoading extends Vue {}
