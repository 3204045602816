














































import {
  Component, Vue, Prop, Watch 
} from 'vue-property-decorator';

import ContentLoader from '@/components/ContentLoader/index.vue';
import Books from '@/components/Book/index.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';

@Component({
  components: {
    ContentLoader,
    Books,
    FeedbackUser
  }
})
export default class ListBooks extends Vue {
  @Prop() private title!: string;
  @Prop() private listBooks: any;
  @Prop({ default: true }) private isLoading!: boolean;

  private time = 0;

  bookProps(data: any, props: string) {
    let base = '';

    if (props in data) {
      base = data[props];
    }

    return base;
  }

  @Watch('listBooks')
  listChange() {
    this.time = setInterval(() => {
      const listBooks: any = this.$refs.itembooks;

      if (this.listBooks?.length === listBooks?.length) {
        clearInterval(this.time);
        this.onResize();
      }
    }, 100);
  }

  @Watch('$responsive.contentwidth')
  onResize() {
    if (!this.$refs.basebooks) return;
    // @ts-ignore
    const baseBooks: any = this.$refs.basebooks;
    // @ts-ignore
    const listBooks: any = this.$refs.books;
    // @ts-ignore
    const itembook: any = this.$refs.itembooks;

    const totalPerLine = Math.round(baseBooks.clientWidth / 220);
    const newWidth = Math.round(baseBooks.clientWidth / totalPerLine) - 2;
    let col = 0;

    if (listBooks.length > 0) {
      itembook.forEach((data: any) => {
        col += 1;

        if (col === totalPerLine) {
          col = 0;
        }

        data.$el.parentElement.setAttribute(
          'style',
          `width: ${newWidth}px; padding: ${5}px; color:red`
        );

        if (data.resize) {
          data.resize();
        }
      });
    }
  }
}
